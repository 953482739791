.container {
  background-image: url("../../assets/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  min-height: 80vh;
}

.login{
    width: 400px;
}
label{
    font-size: 16px;
}
.btn-primary{
    background-color: var(--secondary-color)!important;
    border: none!important;
    width: 100%;
    font-weight: 700;
}
.home {
  background-image: url('../../assets/background.png') ;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  min-height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.countdown-container {
  margin: 0 auto;
  gap: 80px;
  width: 100%;
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.countdown-values {
  display: flex;
  
}

.big-text {
  font-weight: bold;
  font-size:20px;
  line-height: 1;
  margin: 10px;
}

.countdown-value {
  width: 120px;
  margin: 10px 20px;
  text-align: center;
  box-shadow: 0px 0px 14px 2px #21ccd0;
  padding: 10px;
  border-radius: 10px;
}

.countdown-value span {
  font-size: 1.3rem;
}

.countdown-input {
  padding: 9px;
  font-size: 19px;
  border-width: 0px;
  background-color: #ffffff;
  color: #000000;
  border-style: solid;
  border-radius: 7px;
  box-shadow: 0px 0px 3px rgba(66, 66, 66, 0.53);
  outline: none;
}

.countdown-button {
  color: #ffffff;
  background-color: #568ef5;
  font-size: 20px;
  border: 1px solid #2d63c8;
  border-radius: 5px;
  padding: 10px 50px;
  cursor: pointer;
  margin-bottom: 10px;
}

.countdown-button:hover {
  color: #ffffff;
  background-color: #809bfd;
}

.countdown-input-button {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media only screen and (max-width: 800px) {
 .count-wrap{
  display: flex;
 flex-direction: column;
 }
}

.user-team-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
}
.left-user {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--text-color) !important;
}

.right-team {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.teams {
    background-image: url("../../assets/background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  